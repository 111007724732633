"use strict";

$(function () {
  window.site = {};
  window.site.form = {
    init: function init() {
      var _th = this;

      $('.js-phone').mask('+7(999) 999-9999');
      $('form').submit(function () {
        if (!_th.checkForm($(this))) return false;
      });
    },
    checkForm: function checkForm(form) {
      var checkResult = true;
      form.find('.warning').removeClass('warning');
      form.find('input, textarea, select').each(function () {
        if ($(this).data('req')) {
          switch ($(this).data('type')) {
            case 'tel':
              var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

              if (!re.test($(this).val())) {
                $(this).addClass('warning');
                checkResult = false;
              }

              break;

            case 'email':
              var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

              if (!re.test($(this).val())) {
                $(this).addClass('warning');
                checkResult = false;
              }

              break;

            case 'checkbox_personal':
              if (!$(this).is(':checked')) {
                $(this).parents('.checkbox').addClass('warning');
                checkResult = false;
              }

              break;

            default:
              if ($.trim($(this).val()) === '') {
                $(this).addClass('warning');
                checkResult = false;
              }

              break;
          }
        }
      });
      return checkResult;
    }
  }.init();
  window.site.obj = {
    map: function map() {
      var $map = $('.js-map'),
          coords = $map.data('coords').split(',');
      ymaps.ready(function () {
        var myMap = new ymaps.Map("yaMap", {
          center: [coords[0], coords[1]],
          zoom: $map.data('zoom') || 14,
          controls: ['largeMapDefaultSet']
        });
        myMap.controls.add('zoomControl', {
          size: 'small'
        });
        myMap.behaviors.disable('scrollZoom');
        var myPlacemark = new ymaps.Placemark(coords, {}, {
          iconLayout: 'default#image',
          iconImageHref: 'static/img/pin.png',
          iconImageSize: [43, 61]
        });
        myMap.geoObjects.add(myPlacemark);
      });
      return;
    },
    init: function init() {
      var _th = this,
          $body = $('body');

      if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        $body.addClass('ios');
      }

      if ($('.js-map').length) {
        _th.map();
      }

      if ($('.js-mfp').length) {
        $('.js-mfp').magnificPopup({
          type: 'inline',
          midClick: true,
          callbacks: {
            open: function open() {}
          }
        });
      }

      if ('.js-gallery'.length) {
        $('.js-gallery').magnificPopup({
          type: 'image',
          gallery: {
            enabled: true
          }
        });
      }

      $('.js-close-popup').on('click', function () {
        $.magnificPopup.close();
        return false;
      });

      if ($('.js-burger').length) {
        $('.js-burger').on('click', function () {
          var _t = $(this),
              menu = $('.nav');

          if (!_t.hasClass('header__burger--active')) {
            _t.addClass('header__burger--active');

            $body.addClass('open-menu');
            menu.fadeIn(350);
          } else {
            _t.removeClass('header__burger--active');

            menu.fadeOut(350);
            $body.removeClass('open-menu');
          }

          return false;
        });
      }

      if ($('.js-close-nav').length) {
        $('.js-close-nav').on('click', function () {
          $('.header__burger').removeClass('header__burger--active');
          $('.nav').fadeOut(350);
          $body.removeClass('open-menu');
          return false;
        });
      }

      if ($('.js-select').length) {
        $('.js-select').styler({
          selectSmartPositioning: false,
          onFormStyled: function onFormStyled() {}
        });
      }

      if ($('.js-toogle-faq').length) {
        $('.js-toogle-faq').on('click', function () {
          var _t = $(this),
              _tPar = _t.parents('.ifaq__item'),
              _tText = _t.siblings('.ifaq__item-text');

          if (!_tPar.hasClass('ifaq__item--active')) {
            _tText.slideDown(350);

            setTimeout(function () {
              _tPar.addClass('ifaq__item--active');
            }, 350);
          } else {
            _tText.slideUp(350);

            setTimeout(function () {
              _tPar.removeClass('ifaq__item--active');
            }, 350);
          }

          return false;
        });
      }

      if ($('.js-toggle-iprice').length) {
        $('.js-toggle-iprice').on('click', function () {
          var _t = $(this),
              allPopupsPrice = $('.iprice__mobile-popup');

          allPopupsPrice.fadeOut(0);

          _t.siblings('.iprice__mobile-popup').fadeIn(350);

          $body.addClass('blocked');
          return false;
        });
        $('.js-close-iprice-item').on('click', function () {
          $('.iprice__mobile-popup').fadeOut(0);
          $body.removeClass('blocked');
          return false;
        });
      }

      if ($('.js-file').length) {
        var inputFile = $('.js-file');
        inputFile.on('change', function () {
          var _t = $(this),
              over = _t.parents('.uploaded-file'),
              fileItems = over.find('.uploaded-file__items'),
              htmlItem = '';

          if (this.files.length) {
            htmlItem = '<div class="uploaded-file__item"><p class="uploaded-file__item-name">' + this.files[0].name + '</p><button class="uploaded-file__item-del-btn js-del-file" type="button">удалить</button></div>';
            fileItems.append(htmlItem);

            if (!inputFile.parents('.uploaded-file').hasClass('uploaded-file--no-empty')) {
              inputFile.parents('.uploaded-file').addClass('uploaded-file--no-empty');
            }
          }
        });
        $(document).on('click', '.js-del-file', function () {
          var _t = $(this);

          _t.parents('.uploaded-file__item').remove();

          if (_t.parents('.uploaded-file__items').find('.uploaded-file__item').length < 1) {
            $('.uploaded-file').removeClass('uploaded-file--no-empty');
            inputFile.val('');
          }

          return false;
        });
      }

      if ($('.js-rating').length) {
        var rating = $('.js-rating'),
            star = rating.find('.rating-review__star-item');
        star.on('click', function () {
          var _tStar = $(this),
              input = _tStar.parents('.rating-review').find('.rating-review__input');

          if (!_tStar.hasClass('selected')) {
            star.removeClass('active selected');

            _tStar.addClass('selected');

            _tStar.prevAll('.rating-review__star-item').addClass('active');

            input.prop('value', _tStar.index() + 1);
          } else {
            _tStar.removeClass('selected');

            star.removeClass('active');
            input.prop('value', '0');
          }

          return false;
        });
        star.mouseenter(function () {
          var _t = $(this),
              starSelected = $('.rating-review__star-item.selected');

          if (!_t.hasClass('active')) {
            star.removeClass('active');

            _t.addClass('active');

            _t.prevAll('.rating-review__star-item').addClass('active');
          } else {
            if (starSelected.length) {
              if (_t.index() >= starSelected.index()) {
                _t.nextAll('.rating-review__star-item').removeClass('active');
              }
            } else {
              _t.nextAll('.rating-review__star-item').removeClass('active');
            }
          }
        });
        rating.mouseleave(function () {
          if (!$('.rating-review__star-item.selected').length) {
            star.removeClass('active');
          }
        });
      }

      var $contentTables = $(".text table");

      if ($contentTables.length) {
        $contentTables.each(function () {
          $(this).wrap('<div class="table-wrap">');
        });
      }

      var headerTop = $('.header__row--top'),
          btnUp = $('.js-scroll-up');
      btnUp.on('click', function () {
        $('html, body').animate({
          scrollTop: 0
        }, 1200);
        return false;
      });
      $(window).scroll(function () {
        var wst = $(window).scrollTop(),
            ww = $(window).width(),
            wh = $(window).height();

        if (ww > 992) {
          if (wst > headerTop.outerHeight(true)) {
            $body.addClass('hader-fixed-show');
          } else {
            $body.removeClass('hader-fixed-show');
          }
        } else {
          $body.removeClass('hader-fixed-show');
        }

        if (wst > wh) {
          if (!btnUp.hasClass('show')) {
            btnUp.addClass('show');
          }
        } else {
          btnUp.removeClass('show');
        }
      }).trigger('scroll');
      return this;
    }
  }.init();
});